var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"light-blue lighten-5 pb-16 px-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0 d-flex justify-center align-center"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","sm":"9","md":"9","lg":"9"}},[_c('v-card',{staticClass:"white px-auto pb-0 mb-0"},[_c('v-stepper',[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":"","complete-icon":"mdi-home-circle"}},[_vm._v(" CONDO INFO ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2"}},[_vm._v(" CHOOSE PLAN ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" PROPERTY INFO ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" COVERAGES ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"5"}},[_vm._v(" PURCHASE PLAN ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"6"}},[_vm._v(" YOUR POLICY ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"mt-0 pt-1",attrs:{"cols":"12","sm":"9","md":"9","lg":"9"}},[_c('v-card',{staticClass:"mt-0"},[_c('validation-observer',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.next()}},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-form',{ref:"form",staticClass:"px-8 font-weight-bold"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"title-text text-center mt-2"},[_vm._v(" Condo Info ")])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Property Type ")])])],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.propertyTypes,"item-text":"txt","item-value":"value","label":"Select","return-object":"","error-messages":errors,"outlined":""},on:{"change":_vm.checkPropertyType,"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo propertyType',
                        1,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.propertyType),callback:function ($$v) {_vm.propertyType=$$v},expression:"propertyType"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Property Use ")])])],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.dwellUseTypes,"item-text":"txt","item-value":"key","label":"Select","return-object":"","error-messages":errors,"outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo dwellUse',
                        2,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.dwellUse),callback:function ($$v) {_vm.dwellUse=$$v},expression:"dwellUse"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Property Address ")])])],1),_c('v-row',[_c('v-col',[_c('vuetify-google-autocomplete',{ref:"address1",attrs:{"id":"map1","classname":"form-control","outlined":"","placeholder":"Please type your address","country":"us"},on:{"placechanged":_vm.getPropertyAddressData,"click":function($event){return _vm.clickedFieldGlobal(
                      'condoInfo addressAutoComp',
                      3,
                      'Condo Survey v1'
                    )}}})],1)],1),_c('address-info',{attrs:{"address":_vm.propertyAddress,"click-field-values":_vm.addressInfos.currentAddress.clickedFieldGlobalValues,"sequence-numbers":_vm.addressInfos.currentAddress.sequenceNumbers,"survey-type":_vm.surveyType}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Personal Details ")])])],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Is the dwelling owned/titled in the name of a corporation or a business? ")]),_c('v-btn-toggle',{staticClass:"mb-4",model:{value:(_vm.ownedByBusiness),callback:function ($$v) {_vm.ownedByBusiness=$$v},expression:"ownedByBusiness"}},[_c('v-btn',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":true},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo ownedByBusiness - yes',
                        8,
                        'Condo Survey v1'
                      )}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"color":"blanketbutton","text":"","outlined":"","value":false},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo ownedByBusiness - no',
                        8,
                        'Condo Survey v1'
                      )}}},[_vm._v(" No ")])],1)],1)],1),(!_vm.ownedByBusiness)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":{
                    required: true,
                    regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                    max: 20,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"First Name","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo firstname',
                        9,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.currentuser.firstname),callback:function ($$v) {_vm.$set(_vm.currentuser, "firstname", $$v)},expression:"currentuser.firstname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":{
                    required: true,
                    regex: /^[a-zA-Z\ -]+$/,
                    max: 30,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Last Name","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo lastname',
                        10,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.currentuser.lastname),callback:function ($$v) {_vm.$set(_vm.currentuser, "lastname", $$v)},expression:"currentuser.lastname"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.ownedByBusiness)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Corporation/Business Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Corporation/Business Name","color":"primary","outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo businessName',
                        11,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.corporationOwnerName),callback:function ($$v) {_vm.corporationOwnerName=$$v},expression:"corporationOwnerName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('validation-provider',{attrs:{"name":"Corportation/Business Phone","rules":"required|min:12|max:12"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-####'),expression:"'###-###-####'"}],attrs:{"error-messages":errors,"outlined":"","label":"Corportation/Business Phone"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo businessPhone',
                        12,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.currentuser.phone),callback:function ($$v) {_vm.$set(_vm.currentuser, "phone", $$v)},expression:"currentuser.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","label":"Email Address","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo businessEmail',
                        13,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.currentuser.email),callback:function ($$v) {_vm.$set(_vm.currentuser, "email", $$v)},expression:"currentuser.email"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('v-dialog',{ref:"dobDialog",attrs:{"return-value":_vm.currentuser.dob,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.currentuser, "dob", $event)},"update:return-value":function($event){return _vm.$set(_vm.currentuser, "dob", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"Date of Birth","error-messages":errors,"readonly":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                            'condoInfo dob',
                            14,
                            'Condo Survey v1'
                          )}},model:{value:(_vm.currentuser.dob),callback:function ($$v) {_vm.$set(_vm.currentuser, "dob", $$v)},expression:"currentuser.dob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.dobDialog),callback:function ($$v) {_vm.dobDialog=$$v},expression:"dobDialog"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.minDate,"max":_vm.todaysDate,"active-picker":_vm.activeBirthDatePicker,"color":"secondary"},on:{"update:activePicker":function($event){_vm.activeBirthDatePicker=$event},"update:active-picker":function($event){_vm.activeBirthDatePicker=$event}},model:{value:(_vm.currentuser.dob),callback:function ($$v) {_vm.$set(_vm.currentuser, "dob", $$v)},expression:"currentuser.dob"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dobDialog = false
                        _vm.clickedFieldGlobal(
                          'condoInfo dobCancelBtn',
                          15,
                          'Condo Survey v1'
                        )}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dobDialog.save(_vm.currentuser.dob)
                        _vm.clickedFieldGlobal(
                          'condoInfo dobOkBtn',
                          16,
                          'Condo Survey v1'
                        )}}},[_vm._v(" OK ")])],1)],1)],1)],1),(!_vm.ownedByBusiness)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","label":"Email","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo email',
                        17,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.currentuser.email),callback:function ($$v) {_vm.$set(_vm.currentuser, "email", $$v)},expression:"currentuser.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required|min:12|max:12"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-####'),expression:"'###-###-####'"}],attrs:{"error-messages":errors,"outlined":"","label":"Primary Phone Number"},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo phone',
                        18,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.currentuser.phone),callback:function ($$v) {_vm.$set(_vm.currentuser, "phone", $$v)},expression:"currentuser.phone"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('p',[_vm._v(" Year Built "),_c('v-tooltip',{attrs:{"top":"","color":"secondary","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_c('span',[_vm._v("The year construction on the building was completed. Note that if the home is a \"tear-down\" that was rebuilt around some remaining wall or element of the original home, Please use the year that it was originally built, not the year the new construction was completed.")])])],1),_c('validation-provider',{attrs:{"name":"Year Built","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_vm.builtYears,"error-messages":errors,"outlined":""},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo construction.yearBuilt',
                        19,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.construction.yearBuilt),callback:function ($$v) {_vm.$set(_vm.construction, "yearBuilt", $$v)},expression:"construction.yearBuilt"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('p',[_vm._v(" Construction Type | "),_c('a',{staticClass:"primary--text text-decoration-underline",on:{"click":function($event){_vm.constructionTypeDialog = true
                      _vm.clickedFieldGlobal(
                        'condoInfo constructionTypeDialog',
                        20,
                        'Condo Survey v1'
                      )}}},[_vm._v("View Construction Types")])]),_c('validation-provider',{attrs:{"name":"Construction Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"outlined":"","label":"Contruction Type","color":"primary","items":_vm.constructionTypes,"item-text":"txt","item-value":"value","error-messages":errors},on:{"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo constructionType',
                        21,
                        'Condo Survey v1'
                      )}},model:{value:(_vm.construction.constructionCd),callback:function ($$v) {_vm.$set(_vm.construction, "constructionCd", $$v)},expression:"construction.constructionCd"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-inline-flex align-center"},[_c('v-checkbox',{on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'condoInfo lessThan24',
                      22,
                      'Condo Survey v1'
                    )}},model:{value:(_vm.lessThan24),callback:function ($$v) {_vm.lessThan24=$$v},expression:"lessThan24"}}),_vm._v("I have lived at the above address for less than 24 months ")],1)],1),(_vm.lessThan24)?_c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Previous Address ")])])],1),_c('v-row',[_c('v-col',[_c('vuetify-google-autocomplete',{ref:"address2",attrs:{"id":"map2","classname":"form-control","outlined":"","placeholder":"Please type your address","country":"us"},on:{"placechanged":_vm.getPreviousAddressData,"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo prevAddressAutoComp',
                        23,
                        'Condo Survey v1'
                      )}}})],1)],1),_c('address-info',{attrs:{"address":_vm.previousAddress,"click-field-values":_vm.addressInfos.previousAddress.clickedFieldGlobalValues,"sequence-numbers":_vm.addressInfos.previousAddress.sequenceNumbers,"survey-type":_vm.surveyType}})],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-inline-flex align-center"},[_c('v-checkbox',{on:{"click":function($event){return _vm.clickedFieldGlobal(
                      'condoInfo differentMailing',
                      28,
                      'Condo Survey v1'
                    )}},model:{value:(_vm.differentMailing),callback:function ($$v) {_vm.differentMailing=$$v},expression:"differentMailing"}}),_vm._v("I have a different Mailing Address ")],1)],1),(_vm.differentMailing)?_c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"sub-title mt-2"},[_vm._v(" Mailing Address ")])])],1),_c('v-row',[_c('v-col',[_c('vuetify-google-autocomplete',{ref:"address3",attrs:{"id":"map3","classname":"form-control","outlined":"","placeholder":"Please type your address","country":"us"},on:{"placechanged":_vm.getMailingAddressData,"click":function($event){return _vm.clickedFieldGlobal(
                        'condoInfo mailingAddressAutoComp',
                        29,
                        'Condo Survey v1'
                      )}}})],1)],1),_c('address-info',{attrs:{"address":_vm.mailingAddress,"click-field-values":_vm.addressInfos.mailingAddress.clickedFieldGlobalValues,"sequence-numbers":_vm.addressInfos.mailingAddress.sequenceNumbers,"survey-type":_vm.surveyType}})],1):_vm._e(),(_vm.loading)?_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('ellipse-progress',{attrs:{"loading":_vm.loading,"size":180,"img-src":"src/assets/images/Condo-Icon.png","thickness":"5","empty-thickness":"3","line-mode":"out 5","legend":"false","animation":"rs 700 1000","font-size":"1.5rem","color":"#00A1B7","empty-color-fill":"transparent","filename":"Condo-Icon.png"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mb-2 font-weight-bold",attrs:{"x-large":"","color":"primary","disabled":invalid || _vm.loading},on:{"click":function($event){_vm.next()
                      _vm.clickedFieldGlobal(
                        'condoInfo next',
                        34,
                        'Condo Survey v1'
                      )}}},[_vm._v(" Next ")])],1)])],1),_c('error-dialog',{attrs:{"error-dialog":_vm.errorDialog,"error-message":_vm.error}})],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.constructionTypeDialog),callback:function ($$v) {_vm.constructionTypeDialog=$$v},expression:"constructionTypeDialog"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h5 pa-12"},[_vm._v(" Construction types "),_c('br'),_c('br'),_vm._v(" a. Masonry, Brick or Stone: The structure itself is built using masonry, brick or stone. The exterior finish materials may include: brick, paint, siding (including HardiBoard, HardiPlank, etc.), stone and stucco. "),_c('br'),_c('br'),_vm._v(" b. Frame: The structure itself is built using wood or steel framing. The exterior finish materials may include: clapboard or other siding and shakes, metal, vinyl and wood. Note that framing may be visible in unfinished basements or garages. "),_c('br'),_c('br'),_vm._v(" c. Masonry Veneer over Frame: The structure itself is built using wood or steel framing. The exterior finish materials may include: thin veneers of brick, stone and stucco. Note that framing may be visible in unfinished basements or garages. "),_c('br'),_c('br')])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","text":"","x-large":""},on:{"click":function($event){_vm.constructionTypeDialog = false
            _vm.clickedFieldGlobal(
              'condoInfo constructionTypeDialogCloseBtn',
              35,
              'Condo Survey v1'
            )}}},[_vm._v(" Close ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }