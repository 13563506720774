<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                CONDO INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2">
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step step="4">
                COVERAGES
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="5">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="6">
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
            @submit.prevent="next()"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Condo Info
                  </div>
                </v-col>
              </v-row>
              <!------------------------Select: Details ----------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Property Type
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="propertyType"
                      :items="propertyTypes"
                      item-text="txt"
                      item-value="value"
                      label="Select"
                      return-object
                      :error-messages="errors"
                      outlined
                      @change="checkPropertyType"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo propertyType',
                          1,
                          'Condo Survey v1'
                        )
                      "
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Property Use
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="dwellUse"
                      :items="dwellUseTypes"
                      item-text="txt"
                      item-value="key"
                      label="Select"
                      return-object
                      :error-messages="errors"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'condoInfo dwellUse',
                          2,
                          'Condo Survey v1'
                        )
                      "
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <!----------------------- Property Address ----------------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Property Address
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <vuetify-google-autocomplete
                    id="map1"
                    ref="address1"
                    classname="form-control"
                    outlined
                    placeholder="Please type your address"
                    country="us"
                    @placechanged="getPropertyAddressData"
                    @click="
                      clickedFieldGlobal(
                        'condoInfo addressAutoComp',
                        3,
                        'Condo Survey v1'
                      )
                    "
                  >
                  </vuetify-google-autocomplete>
                </v-col>
              </v-row>
              <address-info
                :address="propertyAddress"
                :click-field-values="
                  addressInfos.currentAddress.clickedFieldGlobalValues
                "
                :sequence-numbers="addressInfos.currentAddress.sequenceNumbers"
                :survey-type="surveyType"
              ></address-info>
              <!-------------------------PERSONAL DETAILS--------------------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Personal Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>
                    Is the dwelling owned/titled in the name of a corporation or
                    a business?
                  </p>
                  <v-btn-toggle
                    v-model="ownedByBusiness"
                    class="mb-4"
                  >
                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      :value="true"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo ownedByBusiness - yes',
                          8,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Yes
                    </v-btn>
                    <v-btn
                      color="blanketbutton"
                      text
                      outlined
                      :value="false"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo ownedByBusiness - no',
                          8,
                          'Condo Survey v1'
                        )
                      "
                    >
                      No
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row v-if="!ownedByBusiness">
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    :rules="{
                      required: true,
                      regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/,
                      max: 20,
                    }"
                  >
                    <v-text-field
                      v-model="currentuser.firstname"
                      :error-messages="errors"
                      label="First Name"
                      color="primary"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'condoInfo firstname',
                          9,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    :rules="{
                      required: true,
                      regex: /^[a-zA-Z\ -]+$/,
                      max: 30,
                    }"
                  >
                    <v-text-field
                      v-model="currentuser.lastname"
                      :error-messages="errors"
                      label="Last Name"
                      color="primary"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'condoInfo lastname',
                          10,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="ownedByBusiness">
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Corporation/Business Name"
                    rules="required"
                  >
                    <v-text-field
                      v-model="corporationOwnerName"
                      :error-messages="errors"
                      label="Corporation/Business Name"
                      color="primary"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'condoInfo businessName',
                          11,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Corportation/Business Phone"
                    rules="required|min:12|max:12"
                  >
                    <v-text-field
                      v-model="currentuser.phone"
                      v-mask="'###-###-####'"
                      :error-messages="errors"
                      outlined
                      label="Corportation/Business Phone"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo businessPhone',
                          12,
                          'Condo Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="currentuser.email"
                      outlined
                      color="primary"
                      label="Email Address"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo businessEmail',
                          13,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <v-dialog
                    ref="dobDialog"
                    v-model="dobDialog"
                    :return-value.sync="currentuser.dob"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Date of birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="currentuser.dob"
                          outlined
                          label="Date of Birth"
                          :error-messages="errors"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            clickedFieldGlobal(
                              'condoInfo dob',
                              14,
                              'Condo Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="currentuser.dob"
                      scrollable
                      :min="minDate"
                      :max="todaysDate"
                      :active-picker.sync="activeBirthDatePicker"
                      color="secondary"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          dobDialog = false
                          clickedFieldGlobal(
                            'condoInfo dobCancelBtn',
                            15,
                            'Condo Survey v1'
                          )
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dobDialog.save(currentuser.dob)
                          clickedFieldGlobal(
                            'condoInfo dobOkBtn',
                            16,
                            'Condo Survey v1'
                          )
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row v-if="!ownedByBusiness">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="currentuser.email"
                      outlined
                      color="primary"
                      label="Email"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo email',
                          17,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required|min:12|max:12"
                  >
                    <v-text-field
                      v-model="currentuser.phone"
                      v-mask="'###-###-####'"
                      :error-messages="errors"
                      outlined
                      label="Primary Phone Number"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo phone',
                          18,
                          'Condo Survey v1'
                        )
                      "
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <p>
                    Year Built
                    <v-tooltip
                      top
                      color="secondary"
                      max-width="250px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <span>The year construction on the building was completed.
                        Note that if the home is a "tear-down" that was rebuilt
                        around some remaining wall or element of the original
                        home, Please use the year that it was originally built,
                        not the year the new construction was completed.</span>
                    </v-tooltip>
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Year Built"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="construction.yearBuilt"
                      :items="builtYears"
                      :error-messages="errors"
                      outlined
                      @click="
                        clickedFieldGlobal(
                          'condoInfo construction.yearBuilt',
                          19,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <p>
                    Construction Type |
                    <a
                      class="primary--text text-decoration-underline"
                      @click="
                        constructionTypeDialog = true
                        clickedFieldGlobal(
                          'condoInfo constructionTypeDialog',
                          20,
                          'Condo Survey v1'
                        )
                      "
                    >View Construction Types</a>
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Construction Type"
                    rules="required"
                  >
                    <v-select
                      v-model="construction.constructionCd"
                      outlined
                      label="Contruction Type"
                      color="primary"
                      :items="constructionTypes"
                      item-text="txt"
                      item-value="value"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo constructionType',
                          21,
                          'Condo Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <!--------------------------LESS THAN 24 MONTHS AT ADDRESS-------------------------->
              <v-row>
                <v-col class="d-inline-flex align-center">
                  <v-checkbox
                    v-model="lessThan24"
                    @click="
                      clickedFieldGlobal(
                        'condoInfo lessThan24',
                        22,
                        'Condo Survey v1'
                      )
                    "
                  ></v-checkbox>I have lived at the above address for less than 24 months
                </v-col>
              </v-row>
              <div v-if="lessThan24">
                <v-row>
                  <v-col>
                    <div class="sub-title mt-2">
                      Previous Address
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vuetify-google-autocomplete
                      id="map2"
                      ref="address2"
                      classname="form-control"
                      outlined
                      placeholder="Please type your address"
                      country="us"
                      @placechanged="getPreviousAddressData"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo prevAddressAutoComp',
                          23,
                          'Condo Survey v1'
                        )
                      "
                    >
                    </vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <address-info
                  :address="previousAddress"
                  :click-field-values="
                    addressInfos.previousAddress.clickedFieldGlobalValues
                  "
                  :sequence-numbers="
                    addressInfos.previousAddress.sequenceNumbers
                  "
                  :survey-type="surveyType"
                ></address-info>
              </div>
              <!--------------------------DIFFERENT MAILING ADDRESS-------------------------->
              <v-row>
                <v-col class="d-inline-flex align-center">
                  <v-checkbox
                    v-model="differentMailing"
                    @click="
                      clickedFieldGlobal(
                        'condoInfo differentMailing',
                        28,
                        'Condo Survey v1'
                      )
                    "
                  ></v-checkbox>I have a different Mailing Address
                </v-col>
              </v-row>
              <div v-if="differentMailing">
                <v-row>
                  <v-col>
                    <div class="sub-title mt-2">
                      Mailing Address
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vuetify-google-autocomplete
                      id="map3"
                      ref="address3"
                      classname="form-control"
                      outlined
                      placeholder="Please type your address"
                      country="us"
                      @placechanged="getMailingAddressData"
                      @click="
                        clickedFieldGlobal(
                          'condoInfo mailingAddressAutoComp',
                          29,
                          'Condo Survey v1'
                        )
                      "
                    >
                    </vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <address-info
                  :address="mailingAddress"
                  :click-field-values="
                    addressInfos.mailingAddress.clickedFieldGlobalValues
                  "
                  :sequence-numbers="
                    addressInfos.mailingAddress.sequenceNumbers
                  "
                  :survey-type="surveyType"
                ></address-info>
              </div>
              <v-row
                v-if="loading"
                class="mt-12"
              >
                <v-col class="d-flex justify-center">
                  <ellipse-progress
                    :loading="loading"
                    :size="180"
                    img-src="src/assets/images/Condo-Icon.png"
                    thickness="5"
                    empty-thickness="3"
                    line-mode="out 5"
                    legend="false"
                    animation="rs 700 1000"
                    font-size="1.5rem"
                    color="#00A1B7"
                    empty-color-fill="transparent"
                    filename="Condo-Icon.png"
                  >
                  </ellipse-progress>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      x-large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      :disabled="invalid || loading"
                      @click="
                        next()
                        clickedFieldGlobal(
                          'condoInfo next',
                          34,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Next
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-dialog
                v-model="errorDialog"
                max-width="700"
                persistent
              >
                <v-card>
                  <v-card-title class="justify-center font-weight-bold headline red--text">
                    Error
                  </v-card-title>
                  <v-card-text class="justify-center text-center body primmary--text mt-4">
                    <v-row><v-col>{{ error }}</v-col></v-row>
                  </v-card-text>
                  <v-row>
                    <v-col>
                      <div class="d-flex justify-center">
                        <v-btn
                          color="primary"
                          class="mb-2 mt-4 font-weight-bold"
                          @click="clearError"
                        >
                          Home
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog> -->
              <error-dialog
                :error-dialog="errorDialog"
                :error-message="error"
              ></error-dialog>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="constructionTypeDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-card-text>
          <div class="text-h5 pa-12">
            Construction types <br /><br />

            a. Masonry, Brick or Stone: The structure itself is built using
            masonry, brick or stone. The exterior finish materials may include:
            brick, paint, siding (including HardiBoard, HardiPlank, etc.), stone
            and stucco. <br /><br />

            b. Frame: The structure itself is built using wood or steel framing.
            The exterior finish materials may include: clapboard or other siding
            and shakes, metal, vinyl and wood. Note that framing may be visible
            in unfinished basements or garages. <br /><br />

            c. Masonry Veneer over Frame: The structure itself is built using
            wood or steel framing. The exterior finish materials may include:
            thin veneers of brick, stone and stucco. Note that framing may be
            visible in unfinished basements or garages. <br /><br />
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            text
            x-large
            @click="
              constructionTypeDialog = false
              clickedFieldGlobal(
                'condoInfo constructionTypeDialogCloseBtn',
                35,
                'Condo Survey v1'
              )
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import AddressInfo from '../components/Stillwater/AddressInfo.vue'
  import EllipseProgress from '../components/EllipseProgress.vue'
  import moment from 'moment'

  export default {
    components: {
      AddressInfo,
      EllipseProgress,
    },
    data () {
      return {
        activeBirthDatePicker: null,
        dwellUseTypes: [
          {
            txt: 'Primary (non-seasonal)',
            value: '1',
            key: 1,
          },
          {
            txt: 'Primary (Seasonal)',
            value: '3',
            key: 2,
          },
          {
            txt: 'Secondary (non-seasonal)',
            value: '3',
            key: 3,
          },
          {
            txt: 'Seasonal (secondary)',
            value: '4',
            key: 4,
          },
          {
            txt: 'Farm',
            value: '6',
            key: 5,
          },
          {
            txt: 'Vacant',
            value: '7',
            key: 6,
          },
          {
            txt: 'Occasionally Occupied',
            value: '8',
            key: 7,
          },
          {
            txt: 'Other',
            value: 'OT',
            key: 8,
          },
        ],
        corporationOwnerName: '',
        constructionTypeDialog: false,
        constructionTypes: [
          { txt: 'Masonry, Brick or Stone', value: 'B' },
          { txt: 'Frame', value: 'F' },
          { txt: 'Brick stone, or masonry veneer', value: 'V' },
        ],
        dwellUse: '',
        ownedByBusiness: false,
        existingPolicy: false,
        policyDialog: false,
        addSecondOwner: false,
        additionalInterest: false,
        natureOfInterest: '',
        dobDialog: false,
        todaysDate: moment().format('YYYY-MM-DD'),
        minDate: moment().subtract(75, 'years').format('YYYY-MM-DD'),
        loading: false,
        propertyTypeErrorDialog: false,
        errorDialog: false,
        propertyType: '',
        propertyUse: '',
        lessThan24: false,
        differentMailing: false,
        propertyAddress: {
          addr1: '',
          city: '',
          stateProvCd: '',
          postalCode: '',
        },
        previousAddress: {
          addr1: '',
          city: '',
          stateProvCd: '',
          postalCode: '',
        },
        mailingAddress: {
          addr1: '',
          city: '',
          stateProvCd: '',
          postalCode: '',
        },
        currentuser: {
          firstname: '',
          middleinitial: '',
          lastname: '',
          dob: '',
          email: '',
          phone: '',
        },
        business: {
          name: '',
          phone: '',
          email: '',
          businessAddress: {
            addr1: '',
            city: '',
            stateProvCd: '',
            postalCode: '',
          },
        },
        secondOwner: {
          firstname: '',
          middleinitial: '',
          lastname: '',
          dob: '',
        },
        existingPolicyInfo: {
          policyNumber: '',
          policyCd: '',
          LOBCd: '',
        },
        construction: {
          yearBuilt: '',
          constructionCd: '',
        },
        addressInfos: {
          currentAddress: {
            clickedFieldGlobalValues: [
              'condoInfo address',
              'condoInfo city',
              'condoInfo state',
              'condoInfo zip',
            ],
            sequenceNumbers: [4, 5, 6, 7],
          },
          previousAddress: {
            clickedFieldGlobalValues: [
              'condoInfo prevAddress',
              'condoInfo prevCity',
              'condoInfo prevState',
              'condoInfo prevZip',
            ],
            sequenceNumbers: [24, 25, 26, 27],
          },
          mailingAddress: {
            clickedFieldGlobalValues: [
              'condoInfo mailingAddress',
              'condoInfo mailingCity',
              'condoInfo mailingState',
              'condoInfo mailingZip',
            ],
            sequenceNumbers: [30, 31, 32, 33],
          },
        },
        surveyType: 'Condo Survey v1',
      }
    },
    computed: {
      communications () {
        let communications = ''
        if (this.ownedByBusiness) {
          communications = {
            emailInfo: {
              emailAddr: this.currentuser.email,
            },
            phoneInfo: {
              communicationUseCd: 'Business',
              phoneNumber: this.formattedPhone,
            },
          }
        } else {
          communications = {
            phoneInfo: {
              communicationUseCd: 'Home',
              phoneNumber: this.formattedPhone,
            },
            emailInfo: {
              emailAddr: this.currentuser.email,
            },
          }
        }
        return communications
      },
      builtYears () {
        const years = []
        for (let i = 0; i < 200; i++) {
          years.push(moment().subtract(i, 'years').format('YYYY'))
        }
        return years
      },
      lob () {
        return this.$store.getters.getStillwaterLOB
      },
      policyCd () {
        return this.$store.getters.getStillwaterPolicyCd
      },
      interests () {
        return this.$store.getters.getStillwaterInterestTypes
      },
      formattedPhone () {
        return '+1-' + this.currentuser.phone
      },
      error () {
        let error = this.$store.getters.error
        if (error) {
          this.errorDialog = true
          return error
        }
        return false
      },
      propertyTypes () {
        return [
          { txt: 'Condo', value: 'CD' },
          { txt: 'Townhome', value: 'TH' },
        ]
      },
      owner () {
        let nonCA
        if (this.propertyAddress.stateProvCd !== 'CA') {
          nonCA = true
        } else {
          nonCA = false
        }
        return {
          firstname: this.currentuser.firstname
            ? this.currentuser.firstname
            : null,
          lastname: this.currentuser.lastname ? this.currentuser.lastname : null,
          corporationOwnerName: this.corporationOwnerName
            ? this.corporationOwnerName
            : null,
          corporationOwnerPhone: this.formattedPhone,
          dob: this.currentuser.dob ? this.currentuser.dob : null,
          propertyType: this.propertyType.txt,
          propertyCode: this.propertyType.value,
          dwellUse: this.dwellUse,
          state: this.propertyAddress.stateProvCd,
          nonCA: nonCA,
          construction: this.construction,
          communications: this.communications,
          address: {
            addr1: this.propertyAddress.addr1,
            city: this.propertyAddress.city,
            stateProvCd: this.propertyAddress.stateProvCd,
            postalCode: this.propertyAddress.postalCode,
          },
          mailingAddress: this.differentMailing
            ? {
              addrTypeCd: 'MailingAddress',
              addr1: this.mailingAddress.addr1,
              city: this.mailingAddress.city,
              stateProvCd: this.mailingAddress.stateProvCd,
              postalCode: this.mailingAddress.postalCode,
            }
            : {
              addrTypeCd: 'MailingAddress',
              addr1: this.propertyAddress.addr1,
              city: this.propertyAddress.city,
              stateProvCd: this.propertyAddress.stateProvCd,
              postalCode: this.propertyAddress.postalCode,
            },
          previousAddress: this.lessThan24
            ? {
              addrTypeCd: 'PreviousAddress',
              addr1: this.previousAddress.addr1,
              city: this.previousAddress.city,
              stateProvCd: this.previousAddress.stateProvCd,
              postalCode: this.previousAddress.postalCode,
            }
            : null,
        }
      },
    },
    watch: {
      dobDialog (val) {
        val && setTimeout(() => (this.activeBirthDatePicker = 'YEAR'))
      },
    },
    created () {
      this.$store.dispatch('getPropertyTypes')
      this.$store.dispatch('getDwellUseTypes')
      this.$store.dispatch('getStillwaterLossCodes')
      this.$store.dispatch('getStillwaterInterestTypes')
      this.$store.dispatch('getStillwaterLOB')
      this.$store.dispatch('getStillwaterPolicyCd')
    },
    methods: {
      bdayvalid (value) {
        if (value.length !== 10) {
          return false
        }
        let date = moment(value)
        let valid = false
        valid = date.isValid()
        console.log('valid ', value, valid)
        return valid
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
        this.$router.push({ path: '/' })
      },
      checkPropertyType () {
        console.log(this.propertyType.txt)
        if (this.propertyType.txt === 'Mobile Home') {
          this.propertyTypeErrorDialog = true
        }
      },
      getPropertyAddressData (addressData, placeResultData, id) {
        console.log(addressData)
        let address = this.propertyAddress
        address.addr1 = addressData.name
        address.city = addressData.locality
        address.postalCode = addressData.postal_code
        address.stateProvCd = addressData.administrative_area_level_1
      },
      getPreviousAddressData (addressData, placeResultData, id) {
        let address = this.previousAddress
        address.addr1 = addressData.name
        address.city = addressData.locality
        address.postalCode = addressData.postal_code
        address.stateProvCd = addressData.administrative_area_level_1
      },
      getMailingAddressData (addressData, placeResultData, id) {
        console.log(addressData)
        let address = this.mailingAddress
        address.addr1 = addressData.name
        address.city = addressData.locality
        address.postalCode = addressData.postal_code
        address.stateProvCd = addressData.administrative_area_level_1
      },
      async next () {
        console.log(this.owner)
        this.loading = true
        const { owner } = this
        await Promise.all([
          this.$store.dispatch('setCondoOwner', owner),
          this.$store.dispatch('getH6CondoQuestions', owner),
          this.$store.dispatch('getH6DiscountQuestions', owner),
          this.getCondoQuotes(owner),
        ])
        if (this.error) {
          this.loading = false
          return
        }
        this.$router.push({ path: '/condo/selectplan' })
        this.loading = false
      },

      async getCondoQuotes (owner) {
        await Promise.all([
          this.$store.dispatch('getInitialCondoQuote', {
            deductible: 500,
            hurricaneDeductible: 1000,
            type: 'large',
            amt: 50000,
            owner,
          }),
          this.$store.dispatch('getInitialCondoQuote', {
            deductible: 1000,
            hurricaneDeductible: 2500,
            type: 'medium',
            amt: 50000,
            owner,
          }),
          this.$store.dispatch('getInitialCondoQuote', {
            deductible: 1500,
            hurricaneDeductible: 5000,
            type: 'small',
            amt: 50000,
            owner,
          }),
        ])
      },
    },
  }
</script>

<style lang="scss" scoped>
.green-background {
  background-color: #d8fdd7;
}
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00a1b7;
}
.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005f6c;
  color: white;
}

::v-deep .v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0 !important;
}

::v-deep .v-input--selection-controls.v-input .v-label {
  font-weight: bold;
  color: black;
}

.v-picker--date
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
  .v-btn__content {
  color: #000 !important;
}
</style>
